import { createElement as rc, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { View, Text, Switch, styled, fromTheme } from 'lib_ui-primitives';
import useSearchElement from './useSearchElement';
import FormField from '../formElement/FormField';
import { useToggle } from '../../hooks';
import { metadata } from 'lib_ui-services';

// Always false so that label floats permanently (instead of overlapping the control).
const fieldEmpty = false;

const SwitchWithMargin = styled(Switch)`
    margin-left: ${fromTheme('textPadding')};
    margin-right: ${fromTheme('textPadding')};
    flex-shrink: 0;
`;

const SwitchContainer = styled(View)`
    padding-left: ${fromTheme('textPadding')};
    padding-top: ${fromTheme('textPadding')};
    margin-top: ${fromTheme('textMargin')};
    align-items: center;
`;

const ToggleOptions = styled(Text).attrs({ name: 'toggle-options' })`
    color: ${({ selected, theme }) => {
        return selected ? theme.defaultFontColor : theme.disabledFontColor;
    }};
`;
ToggleOptions.displayName = 'ToggleOptions';

/**
 * @typedef {Object} Props
 * @property {Object} hNode
 */
/** @type {import('react').FC<Props>} */
function ToggleSearch(props) {
    const {
        hNode,
        hNode: { id, title, propertyName, toggleValues, neutralOption, includeUndefinedAsFalse },
        disabled
    } = props || { hNode: {} };

    const valueToFilter = useCallback(
        state => {
            // If the state is a boolean, we can use it to filter
            if (typeof state === 'boolean') {
                const path = metadata.getPathToProperty(hNode);
                const filters = [
                    { [path]: state }
                ];
                if (includeUndefinedAsFalse && !state) {
                    filters.push({ [path]: {$exists: false} });   // include undefined as false
                }
                return {
                    $or: filters
                };
            }
            // Otherwise, the toggle is in the neutral state, clear the filter
            return {};
        },
        [hNode, includeUndefinedAsFalse]
    );

    const { value, setValue, active, onFocus, onBlur } = useSearchElement(props, valueToFilter, neutralOption);
    const [toggleState, toggle] = useToggle(value, neutralOption);

    // Update the search element value when the toggle state changes
    useEffect(() => {
        setValue(toggleState);
    }, [toggleState, setValue]);

    // prettier-ignore
    return rc(FormField, { id, hNode, title, active, fieldEmpty },
        rc(SwitchContainer, null,
            // Explcitly check for true or false to prevent the neutral option from being selected
            rc(ToggleOptions, { selected: value === false }, toggleValues[0]),
            rc(SwitchWithMargin, { id, value: value, onClick: toggle, disabled, name: propertyName, onFocus, onBlur }),
            rc(ToggleOptions, { selected: value === true }, toggleValues[1])
        )
    );
}

ToggleSearch.propTypes = {
    hNode: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        propertyName: PropTypes.string.isRequired,
        toggleValues: PropTypes.arrayOf(PropTypes.string).isRequired,
        neutralOption: PropTypes.string
    }).isRequired,
    disabled: PropTypes.bool
};

export default ToggleSearch;
