import { constants, dbViews } from 'lib_ui-services';
const { getDbView } = dbViews;
import basicWillExport from '../../namespace/relation/export_namespace_relation/willExport_namespace_relation';
import { format } from 'date-fns';

export default {
    verb: 'willExport',
    namespace: 'item',
    relation: 'item',
    description: 'Intercept export definition and denormalize stock inventory',
    // run after the default willExport_namespace_relation rule
    priority: (basicWillExport.priority || 0) - 5,
    useCaseIds: [constants.useCaseIds.RIT],
    logic: willExport
};

async function willExport(payload) {
    const { data, context: { namespace, relation } } = payload;
    let records = data.dbViewData;
    if (!records) {
        records = await getDbView(namespace, relation, data.viewName)?.data;
    }
    records = records || [];
    const denormalizedRecords =  records.flatMap(item => {
        return Array.isArray(item.location)
            ? item.location.map(location => ({ ...item, ...location }))
            : item;
    });
    const timestamp = format(new Date(), "yyyyMMMdd'T'hhmmaaa");
    Object.assign(data, {
        data: denormalizedRecords,
        count: denormalizedRecords.length,
        fileName: `RedBeam Inventory Export_${timestamp}`,
        columns
    });
    return payload;
}

const columns = [
    {
        sequence: 0,
        hNodeType: 'Text',
        hNodeTypeGroup: 'listColumn',
        title: 'Item ID',
        propertyName: 'assetNo'
    },
    {
        sequence: 1,
        hNodeType: 'Text',
        hNodeTypeGroup: 'listColumn',
        title: 'Description',
        propertyName: 'description'
    },
    {
        sequence: 2,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Item Type',
        propertyName: 'item:type.title',
        foreignNamespace: 'item',
        foreignRelation: 'type'
    },
    {
        sequence: 3,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Manufacturer',
        propertyName: 'organization:manufacturer.title',
        foreignNamespace: 'organization',
        foreignRelation: 'manufacturer'
    },
    {
        sequence: 4,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Model',
        propertyName: 'item:model.title',
        foreignNamespace: 'item',
        foreignRelation: 'model'
    },
    {
        sequence: 5,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Company',
        propertyName: 'location:company.title',
        foreignNamespace: 'location',
        foreignRelation: 'company'
    },
    {
        sequence: 6,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Building',
        propertyName: 'location:building.title',
        foreignNamespace: 'location',
        foreignRelation: 'building'
    },
    {
        sequence: 7,
        hNodeType: 'ForeignRelationText',
        hNodeTypeGroup: 'listColumn',
        title: 'Vendor',
        propertyName: 'organization:vendor.title',
        foreignNamespace: 'organization',
        foreignRelation: 'vendor'
    },
    {
        sequence: 8,
        hNodeType: 'Currency',
        hNodeTypeGroup: 'listColumn',
        title: 'Price',
        propertyName: 'price',
        dataType: 'currency'
    },
    {
        sequence: 9,
        hNodeType: 'Text',
        hNodeTypeGroup: 'listColumn',
        title: 'Notes',
        propertyName: 'notes'
    },
    {
        sequence: 10,
        hNodeType: 'LocalVerboseDateTime',
        dataType: 'localVerboseDateTime',
        hNodeTypeGroup: 'listColumn',
        title: 'Date Added',
        propertyName: 'meta.createdTime'
    },
    {
        sequence: 11,
        hNodeType: 'LocalVerboseDateTime',
        dataType: 'localVerboseDateTime',
        hNodeTypeGroup: 'listColumn',
        title: 'Last Updated',
        propertyName: 'meta.modifiedTime'
    },
    {
        sequence: 12,
        hNodeType: 'MappedBoolean',
        dataType: 'mappedBoolean',
        hNodeTypeGroup: 'listColumn',
        title: 'Active',
        propertyName: 'active',
        mappedTrueValue: 'Yes',
        mappedFalseValue: 'No'
    }
];
